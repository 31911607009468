import React, { useEffect, useState } from 'react';

import { WithTheme } from "@tellescope/react-components/lib/esm/theme"

import { THEME } from "./constants"
import { LinearProgress } from '@mui/material';

const load_app = () => new Promise<JSX.Element>((resolve, reject) => {
   const appImport = window.location.href.includes("/e/")     ? import("./enduser/routing")
                   : window.location.href.includes("/embeds/content")  ? import("./pages/CMS/PublicViewer") 
                   : window.location.href.includes("/embeds/")  ? import("./embedded/routing") 
                   : window.location.href.includes("/webview/")  ? import("./webview/routing")
                   : window.location.href.includes("/video/startlink")  ? import("./pages/video_start_link")
                   : window.location.href.includes("/form?")  ? import("./pages/form") // deprecated, new links should start /e/, can delete by end of August 2022
                                                              : import("./App")
   appImport
   .then(module => resolve(<module.default/>))
   .catch(reject)
 })

const AppRouter = () => {
  const [LoadedApp, setLoadedApp] = useState(<LinearProgress/>)

  useEffect(() => {
    load_app()
    .then(setLoadedApp)
    .catch(console.error)
  }, [])

  return (
    <WithTheme theme={THEME}>
      {LoadedApp}
    </WithTheme>

  )
}

export default AppRouter